import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { DataService } from '../../shared/data.service';

@Component({
  selector: 'app-titlebar',
  templateUrl: './titlebar.component.html',
  styleUrls: ['./titlebar.component.css']
})
export class TitlebarComponent implements OnInit {

//  @Output() EnvSelected = new EventEmitter();
environments = [{id: '1', name: "Demo account"}];  

  constructor(private router: Router, private _translate: TranslateService, private _data: DataService) { 
    this.subscription  = this._data.environments.subscribe((env: Array<any>) => {
      if (env && env.length > 0) {
        this.environments = env;
        this.selectedEnvironment = env[0].id;
        this.visualEnvironment = env[0].name;
        this.selectEnvironment(env[0].id);
      }
		});
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  apptitle: string = 'SoftTouch Dashboard';
  subscription;
  selectedEnvironment: string;
  visualEnvironment: string;
  languages = [{'code': 'nl', 'desc': 'Nederlands', 'flag': 'flag_nl'},
               {'code': 'fr', 'desc': 'Francais', 'flag': 'flag_fr'},
               {'code': 'en', 'desc': 'English', 'flag': 'flag_en'}
              ];
  selectedLang=this.languages[0];
  userName: string;
  userEmail: string;

  ngOnInit() {
    //this.environments = this._data.environments;
//    this.environments = JSON.parse(localStorage.getItem("accounts"));
//    console.log(this.environments);
//    this.selectedEnvironment = JSON.parse(localStorage.getItem("accounts"))[0].id;
//    this._data.switchEnv(JSON.parse(localStorage.getItem("accounts"))[0]);
//    this.EnvSelected.emit(this.selectedEnvironment);
//    this.visualEnvironment = JSON.parse(localStorage.getItem("accounts"))[0].name;
//    this.userName = localStorage.getItem("fullname");
//    this.userEmail = localStorage.getItem("email");
  }

  selectEnvironment(envi) {
    var value = this.environments.filter(function (el) {
      return (el.id === envi);
    });
    this.selectedEnvironment = value[0].id;
    this.visualEnvironment = value[0].name;
    this._data.switchEnv(value[0]);
//    this.EnvSelected.emit(this.selectedEnvironment);
    console.log(value[0]);
//    this._data.switchEnv(value[0]);
//    this._data.getListener.next({action: 'get'});
  }

  selectLang(language) {
    this.selectedLang = language;
    this._translate.use(language.code);
  }

  Logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("accounts");
    localStorage.removeItem("lastCheckAccounts");
    this._data.setEnvironments(undefined);
    this._data.switchEnv(undefined);
    this._data.periods = undefined;
    this.router.navigate(["/sign-in"]);
  }

  GoToSettings() {
    console.log("settingsclick");
    this.router.navigate(["/home/settings"]);
  }

  GoToDashboard1() {
    if(this.router.url === '/home/dashboard1') {
      console.log("refresh!!!")
      let env = {};
      env['id'] = this.selectedEnvironment;
      env['name'] = this.visualEnvironment;
      this._data.switchEnv(env);
    }
    else {
      this.router.navigate(["/home/dashboard1"]);
    }




  }
}
