import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  datetext(datetoconvert): string {
    var month = datetoconvert.getMonth()+1;
    if (month < 10)
       month = '0'+ month;
    var day = datetoconvert.getDate();
    if (day < 10)
      day = '0'+ day;
    var textday =  datetoconvert.getFullYear() + '/' + month + '/' + day;
    return textday;
  } 

  createdatefromtext(textday: string): Date {
    var d = new Date(textday);
    return d;
  }

  today() {
    var today = new Date();
    return today;
  }

  yesterday() {
    var yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return yesterday;
  }

  firstdayofweek(date) {
    var newdate = new Date();
    newdate.setTime(date.getTime());
    var aantal = 0;
    if(newdate.getDay() == 0)
      aantal = 6;
    else
      aantal = newdate.getDay() - 1;
    newdate.setDate(newdate.getDate() - aantal);
    return newdate;
//    return newdate.setDate(newdate.getDate() - aantal);
  }

  lastdayofweek(date) {
    var newdate = new Date();
    newdate.setTime(date.getTime());
    var aantal = 0;
    if(newdate.getDay() != 0)
      aantal = 7 - newdate.getDay();
      newdate.setDate(newdate.getDate() + aantal);
    return newdate;
  }

  firstdayofmonth(date) {
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    return firstDay;
  }

  lastmonth(date) {
    var newdate = this.firstdayofmonth(date);
    newdate.setDate(newdate.getDate() - 1);
    newdate.setDate(date.getDate());
    return newdate;
  }

  lastdayofmonth(date) {
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return lastDay;
  }

  firstdayofyear(date) {
    var lastDay = new Date(date.getFullYear(), 0, 1);
    return lastDay;
  }

  lastdayofyear(date) {
    var lastDay = new Date(date.getFullYear(), 11, 31);
    return lastDay;
  }

  lastyeardate(date) {
    var newdate = new Date();
    newdate.setTime(date.getTime());
    newdate.setFullYear(newdate.getFullYear() - 1);
    return newdate;
  }

  lastyearday(date) {
    var newdate = new Date();
    newdate.setTime(date.getTime());
    var dateday = newdate.getDay();
    if (dateday ==  0)
      dateday = 7;
    newdate.setFullYear(newdate.getFullYear() - 1);
    newdate.setDate(newdate.getDate() - newdate.getDay() + dateday);
    return newdate;
  }

  lastweek(date) {
    var newdate = new Date();
    newdate.setTime(date.getTime());
    newdate.setDate (newdate.getDate() - 7);
    return newdate;
  }

  getWeek(date) {
    if (!(date instanceof Date)) date = new Date();
  
    // ISO week date weeks start on Monday, so correct the day number
    var nDay = (date.getDay() + 6) % 7;
  
    // ISO 8601 states that week 1 is the week with the first Thursday of that year
    // Set the target date to the Thursday in the target week
    date.setDate(date.getDate() - nDay + 3);
  
    // Store the millisecond value of the target date
    var n1stThursday = date.valueOf();
  
    // Set the target to the first Thursday of the year
    // First, set the target to January 1st
    date.setMonth(0, 1);
  
    // Not a Thursday? Correct the date to the next Thursday
    if (date.getDay() !== 4) {
      date.setMonth(0, 1 + ((4 - date.getDay()) + 7) % 7);
    }
  
    // The week number is the number of weeks between the first Thursday of the year
    // and the Thursday in the target week (604800000 = 7 * 24 * 3600 * 1000)
    return 1 + Math.ceil((n1stThursday - date) / 604800000);
  }

  dayText(date) {
    var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return days[date.getDay()] + ' ' + date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
  }


}
