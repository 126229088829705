export class Pos {
    Posid       : string;
    Description : string;
    Visible     : boolean;  
    Storeid     : string;    
    
    constructor(Posid: string, Description: string, Storeid: string) {
        this.Posid = Posid;
        this.Description = Description;
        this.Storeid = Storeid;
        this.Visible = true;
      }
}

export class Store {
    Storeid     : string;
    Description : string;
    Visible     : boolean;    
    
    constructor(Storeid: string, Description: string) {
        this.Storeid = Storeid;
        this.Description = Description;
        this.Visible = true;
      }
}

export class Environment {
    EnvID   : string;
    EnvName : string;
    enabled     : boolean;
    Pos         : Array<Pos>;
    Stores      : Array<Store>;

    constructor(id: string, name: string) {
        this.EnvID   = id;
        this.EnvName = name;
        this.enabled = true;
    }
}

export class Account {
    AccountID    : string;
    Environments : Array<Environment>;

    constructor(id: string) {
        this.AccountID = id;
    }
}

export class DashboardData {
    Account  : string;
    Entity   : string;
    From     : Date;
    To       : Date;
    id       : string;
    desc     : string;
    Goods    : Number;
    Discount : Number;
    Revenue  : Number;
    Tickets  : Number;
    Quantity : Number;
    
    constructor( dash: any ) {
        this.Account = dash.account_name;
        this.Entity = dash.entity;
        this.From = dash.from_date;
    }
    
}