import { Injectable } from '@angular/core';
import { User } from './user.model';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import 'rxjs/add/operator/catch';
//import 'rxjs/add/operator/throw';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private _http: HttpClient) { 

  }
/*
  users: User[] = [
    {
    'UserName'  : 'admin',
    'PassWord'  : 'admin',
    'Email'     : 'admin@softtouch.be',
    'FirstName' : 'Admin',
    'LastName'  : 'Istrator',
    'token'     : '34367669185a6eb2d8a93953c0de0c20b2524ff3'
    },
    {
    'UserName'  : 'othello',
    'PassWord'  : 'othello',
    'Email'     : 'othello@softtouch.be',
    'FirstName' : 'Guy',
    'LastName'  : 'Van Othello',
    'token'     : '34367669185a6eb2d8a93953c0de0c20b2524ff3'
    },
    {
    'UserName'  : 'jeroen',
    'PassWord'  : 'jeroen',
    'Email'     : 'jeroen@softtouch.be',
    'FirstName' : 'Jeroen',
    'LastName'  : 'Van Hoorde',
    'token'     : '34367669185a6eb2d8a93953c0de0c20b2524ff3'
    },
    {
    'UserName'  : 'tom',
    'PassWord'  : 'tom',
    'Email'     : 'tom@softtouch.be',
    'FirstName' : 'Tom',
    'LastName'  : 'Maes',
    'token'     : '34367669185a6eb2d8a93953c0de0c20b2524ff3'
    }       
  ];
*/
/*  accounts = [
    {'token' : '34367669185a6eb2d8a93953c0de0c20b2524ff3',
     'accounts' : ['131', '278']
    }
  ]
*/

  validateLogin(_usr: String, _pass: String):any {
    if(_usr != undefined) {
      return this.checklogin(_usr, _pass)
              .map(result => result)
              .catch(this.errorHandler);
/*      
      var selected : User[] = this.users.filter(
        user => user.UserName === _usr);
        console.log(selected[0]);
      if(selected[0] != undefined) 
      {
        if(selected[0].PassWord === _pass && selected.length == 1) {
            return selected[0];
        } else {
          return null;
        }
      }
    } else {
*/ 
 
    } else
    {
      console.log('nope');
      return null;
    }
   
  }

errorHandler(error: HttpErrorResponse) {
  console.log("ERROR HANDLER")
  return Observable.throw(error.message || "Server Error");
}

  getAccounts(_token: string): any {
    if(_token != undefined) {
      return this.validateMe(_token)
			        .map(result => result);
/*      this._web.validateMe()
            .subscribe((res:any) => {
              console.log(res);
              console.log(res.accounts);
              return res.accounts;
            });
      var selected = this.accounts.filter(
        user => user.token === _token);
      return selected[0].accounts;
     } else {
       return null;
     }
*/
    }
  }

	validateMe(token: string) {
		const headers = new HttpHeaders()
				.set("Content-Type", "application/json")
				.set('Authorization', token);
		var url = "https://api.softtouch.eu/1/me";
		return this._http.get(url, {headers})
	.map(result => result);
  }

  private checklogin(_usr: String, _pass: String) {
    const headers = new HttpHeaders()
    .set("Content-Type", "application/json")
    .set('Accept', "application/json");
    var body = {
      "email": _usr,
      "password": _pass
        };
    var url = 'https://api.softtouch.eu/1/login';
    return this._http.post(url, body, { headers })
      .map(result => result);
  }

}

