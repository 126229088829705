import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { DateService } from '../../shared/date.service';
import { DataService } from '../../shared/data.service';

@Component({
  selector: 'app-datefilter',
  templateUrl: './datefilter.component.html',
  styleUrls: ['./datefilter.component.css']
})
export class DatefilterComponent implements OnInit {

  @Output() PeriodSelected = new EventEmitter();
 
  SavePeriods() {
    var periods = {'period': this.selectedPeriod, 'compare': this.selectedComparePeriod};
    this.PeriodSelected.emit(periods);
  }
  setfilterOpenState(i) {
    this.filterOpenState = i;
  }

  days = [];
  filterOpenState = 0;

  constructor(private _date: DateService, private _data: DataService
    ) { 
console.log('DF constructor');
  this.days = [
          {'name': 'periods.today', 
           'from': _date.datetext(_date.today()),
           'till' : _date.datetext(_date.today()),
           'compare': [{'name': 'periods.yesterday',
                        'from': _date.datetext(_date.yesterday()),
                        'till': _date.datetext(_date.yesterday())}, 
                       {'name': 'periods.lastyearcomparableday',
                        'from': _date.datetext(_date.lastyearday(_date.today())),
                        'till': _date.datetext(_date.lastyearday(_date.today()))}, 
                       {'name': 'periods.lastyearsamedate',
                        'from': _date.datetext(_date.lastyeardate(_date.today())),
                        'till': _date.datetext(_date.lastyeardate(_date.today()))}
                      ]
          },
          {'name': 'periods.yesterday', 
           'from': _date.datetext(_date.yesterday()),
           'till' : _date.datetext(_date.yesterday()),
           'compare': [{'name': 'periods.lastyearcomparableday',
                        'from': _date.datetext(_date.lastyearday(_date.yesterday())),
                        'till': _date.datetext(_date.lastyearday(_date.yesterday()))},  
                       {'name': 'periods.lastyearsamedate',
                        'from': _date.datetext(_date.lastyeardate(_date.yesterday())),
                        'till': _date.datetext(_date.lastyeardate(_date.yesterday()))}
                      ]
          },
          {'name': 'periods.thisweek', 
           'from': _date.datetext(_date.firstdayofweek(_date.today())),
           'till': _date.datetext(_date.today()),
           'compare': [{'name': 'periods.lastweek',
                        'from': _date.datetext(_date.lastweek(_date.firstdayofweek(_date.today()))),
                        'till': _date.datetext(_date.lastweek(_date.today()))
                       }, 
                       {'name': 'periods.lastyearcomparabledates',
                        'from': _date.datetext(_date.lastyearday(_date.firstdayofweek(_date.today()))),
                        'till': _date.datetext(_date.lastyearday(_date.today()))
                       }
                      ]
          },
          {'name': 'periods.lastweek', 
          'from': _date.datetext(_date.lastweek(_date.firstdayofweek(_date.today()))),
          'till': _date.datetext(_date.lastweek(_date.lastdayofweek(_date.today()))),
          'compare': [{'name': 'periods.lastyearcomparabledates',
                       'from': _date.datetext(_date.lastyearday(_date.lastweek(_date.firstdayofweek(_date.today())))),
                       'till': _date.datetext(_date.lastyearday(_date.lastweek(_date.lastdayofweek(_date.today()))))
                      }
                     ]
         },
         {'name': 'periods.thismonth', 
         'from': _date.datetext(_date.firstdayofmonth(_date.today())),
         'till': _date.datetext(_date.today()),
         'compare': [{'name': 'periods.lastmonth',
                      'from': _date.datetext(_date.firstdayofmonth(_date.lastmonth(_date.today()))),
                      'till': _date.datetext(_date.lastmonth(_date.today()))
                     },  
                     {'name': 'periods.lastyearsamedate',
                      'from': _date.datetext(_date.lastyeardate(_date.firstdayofmonth(_date.today()))),
                      'till': _date.datetext(_date.lastyeardate(_date.today()))}
                    ]
        },
        {'name': 'periods.lastmonth', 
         'from': _date.datetext(_date.firstdayofmonth(_date.lastmonth(_date.today()))),
         'till': _date.datetext(_date.lastdayofmonth(_date.lastmonth(_date.today()))),
         'compare': [{'name': 'periods.lastyearsamedate',
                      'from': _date.datetext(_date.firstdayofmonth(_date.lastyearday(_date.lastmonth(_date.today())))),
                      'till': _date.datetext(_date.lastdayofmonth(_date.lastyearday(_date.lastmonth(_date.today()))))
                    }
                   ]
       },
       {'name': 'periods.thisyear', 
       'from': _date.datetext(_date.firstdayofyear(_date.today())),
       'till': _date.datetext(_date.today()),
       'compare': [{'name': 'periods.lastyear',
                    'from': _date.datetext(_date.firstdayofyear(_date.lastyeardate(_date.today()))),
                    'till': _date.datetext(_date.lastyeardate(_date.today()))
                  }
                 ]
        }
         ];

         this._data.currentEnv.subscribe((response:any) => {
            if (response) {
              var periods = {'period': this.selectedPeriod, 'compare': this.selectedComparePeriod};
              this.PeriodSelected.emit(periods);
            }
         })
        }

  selectedPeriod;
  selectedComparePeriod;

  filterFieldFrom: string;
  filterFieldTill: string;

  ngOnInit()
  {
    console.log('DF init');
    if (this._data.periods) {
      let i = this.days.findIndex(period => period.name == this._data.periods['period'].name);
      this.selectedPeriod = this.days[i];
      let j = this.selectedPeriod.compare.findIndex(period => period.name == this._data.periods['compare'].name);
      this.selectedComparePeriod = this.days[i].compare[j];  
    } else {
      this.selectedPeriod = this.days[0];
      this.selectedComparePeriod = this.days[0].compare[0];  
    }
    this.filterFields();
  }

  selectedItem(value) {
    this.selectedComparePeriod = value.value.compare[0];
    this.filterFields();
    this.SavePeriods();
  }
  selectedCompareItem(value) {
    this.filterFields();
    this.SavePeriods();
    this.filterOpenState = 0;
  }

  filterFields() {
    this.filterFieldFrom = this.showFilterFields(this.selectedPeriod);
    this.filterFieldTill = this.showFilterFields(this.selectedComparePeriod);    
  }

  showFilterFields(period: any): string {
    var text = period.from;
    if (period.from != period.till)
    {
      text += ' - ' + period.till;
    }
    return text;
  }
}
