//import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { Dashboard1Component } from './home/dashboard1/dashboard1.component';
import { SettingsComponent } from './home/settings/settings.component'
import { DetailComponent } from './home/detail/detail.component'
import { SignInComponent } from './user/sign-in/sign-in.component';
import { UserComponent } from './user/user.component';

import { AuthGuard } from './auth/auth.guard';

export const appRoutes: Routes = [
    { path : 'home', component : HomeComponent,canActivate:[AuthGuard],
      children: [{path: '', component: Dashboard1Component},
                 {path: 'dashboard1', component: Dashboard1Component},
                 {path: 'settings', component: SettingsComponent},
                 {path: 'detail', component: DetailComponent}
                ]
    },
    { path : 'sign-in', component : UserComponent,
      children: [{ path: '', component: SignInComponent }]
    },
    { path : '', redirectTo:'/sign-in', pathMatch : 'full'}   

];