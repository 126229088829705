import { Component, OnInit } from '@angular/core';
import { UserService } from '../shared/user.service';
import { DataService } from '../shared/data.service';
import { DatastorageService } from '../shared/datastorage.service';
import { User } from '../shared/user.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

//  fullImagePath: string;
//  lastCheckAccounts: string;
//  timeDiff: string;
  currentUser: User;
  envs = [];
  subscription; 
  constructor(private _user: UserService, private _data: DataService, private _ds: DatastorageService) {
    console.log(JSON.parse(localStorage.getItem("user")));
    console.log(JSON.parse(localStorage.getItem("user")).token);

    this.currentUser = JSON.parse(localStorage.getItem("user"));
    console.log('curuser')
    //    this.fullImagePath = '../../assets/bganimation1.gif'
  }

  ngOnDestroy() {
    console.log("ngOnDestroy DB1");
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    console.log(this.currentUser);
    this.subscription = this._user.getAccounts(this.currentUser.token).subscribe((res:any) => {
      this._data.setEnvironments(res.accounts);
//      this._data.switchEnv(res.accounts[0]);
    });

//    this.lastCheckAccounts = JSON.parse(localStorage.getItem("lastCheckAccounts"));
//    var date = new Date(this.lastCheckAccounts);
//    var now = new Date();
//    var diff = now.getTime() - date.getTime();
//    this.timeDiff = String(Math.round(diff / 60 / 1000));
  }

/*  createCard_backup(id: number, description: string, value, compare, decimals) {
    return {'id': id,
    'description': description,
    'value': Math.round(value),
    'compare': Math.round(compare),
    'class': Math.round(value) >= Math.round(compare) ? 'pos' : 'neg',
    'arrow': Math.round(value) >= Math.round(compare) ? 'arrow_upward' : 'arrow_downward',
    'pct': Math.round(-(100 - (Math.floor(value) / Math.floor(compare) * 100))),
    'bar': Math.round(-(100 - (Math.floor(value) / Math.floor(compare) * 100))) < 0 ? 
           Math.round(100-(100 - (Math.floor(value) / Math.floor(compare) * 100))) : 
           Math.round(-(100 - (Math.floor(value) / Math.floor(compare) * 100)))
   }
  }
*/
}