import { Component, OnInit } from '@angular/core';
import { User } from '../../shared/user.model';
import { UserService } from '../../shared/user.service';
import { WebService } from '../../shared/web.service'
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Pos, Store, Account } from '../../shared/pos-store.model'
import {MatSnackBar} from '@angular/material';

//import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {
  fullImagePath: String;
  loading: boolean = false;

  constructor(private router: Router, private _user: UserService, private _web: WebService, public snackBar: MatSnackBar) {
    this.fullImagePath = '../../assets/bganimation1.gif';
//    localStorage.removeItem('settings');
    for (var i = 0; i < localStorage.length; i++){
      //console.log(localStorage.getItem(localStorage.key(i)));
      console.log(localStorage.key(i) + "=[" + localStorage.getItem(localStorage.key(i)) + "]");
    }
  }

  username: string;
  password: string;
  userInfo: User;
  accounts = [];
  errorMsg;

  ngOnInit() {
  }

  login(event: any): void {
    if ((event === null || (event && event.keyCode === 13)) && this.username != '' && this.password != '') {
      this.loading = true;
//      if ((this.userInfo = this._user.validateLogin(this.username, this.password)) && (this.username != '')) {
      if (this.username != '' && this.username != undefined ) {
        this._user.validateLogin(this.username, this.password).subscribe((res:any) => {

          console.log(res[0]);
          if(res[0] != undefined) {
            this.userInfo = {'token': res[0].access_token};
            console.log(this.userInfo);
            localStorage.setItem("user", JSON.stringify(this.userInfo));
            this.loading = false;
  
  //          console.log(JSON.parse(localStorage.getItem("user")));
  //          console.log(JSON.parse(localStorage.getItem("user")).token);
            
            this.router.navigate(["home"]);  
          }
          else {
            console.log("error");
            this.loading = false;  
            this.openSnackBar("Error logging in, please contact SoftTouch", "");
          }
        },
        error => {
          this.errorMsg = error;
          console.log("error");
          this.loading = false;
          this.openSnackBar("User and/or Password are incorrect!", "");
        });
      }
      else
      {
        console.log('ERROR LOGIN');
        this.loading = false;
        this.openSnackBar("User and/or Password are incorrect!", "");
      }
    }
    
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      panelClass: ['blue-snackbar']
    });
  }

}



        /*        
        this._user.getAccounts(this.userInfo.token)
            .subscribe((res:any) => {
              localStorage.setItem("email", res.email);
              localStorage.setItem("fullname", res.fullname);
              localStorage.setItem("accounts", JSON.stringify(res.accounts));
              console.log(res);
              this._web.token = this.userInfo.token;
              let accs = [];
              for (let acc of res.accounts)
              {
                accs.push(acc.id);
              }
              forkJoin(
                this._web.getStoresFromAccounts(accs),
                this._web.getPosFromAccounts(accs)
              )
              .subscribe(([res1, res2]) => {
                for (var _i=0; _i < res.accounts.length; _i++) {
//                  if (_i == 3) {
                    var account = new Account(res.accounts[_i].id, res.accounts[_i].name);
//                    console.log(res.accounts[_i]);
//                    console.log(res1[_i]);
//                    console.log(res2[_i]);
                    let pos = [];
                    for (var _j=0; _j < res2[_i].length; _j++) {
                      let x = new Pos(res2[_i][_j].key, res2[_i][_j].txt, res2[_i][_j].store_id);
                      pos.push(x);
                    }
                    pos.sort(function(obj1, obj2) {
                      return obj1.Posid - obj2.Posid;
                    });
                    let stores = [];
                    for (var _j=0; _j < res1[_i].length; _j++) {
                      let x = new Store(res1[_i][_j].key, res1[_i][_j].txt);
                      stores.push(x);
                    }
                    stores.sort(function(obj1, obj2) {
                      return obj1.Storeid - obj2.Storeid;
                    });
                    account.AccountID = res.accounts[_i].id;
                    account.Pos = pos;
                    account.Stores = stores;
                    this.accounts.push(account);
                    console.log("---");
//                  }
                }
                console.log(this.accounts);
                //                this.router.navigate(["home"]);
              });
        });
*/
/*        localStorage.setItem("accounts", JSON.stringify(this.accounts));
        var today = new Date();
        localStorage.setItem("lastCheckAccounts", JSON.stringify(today));

      }
      else if (this.username != '' && this.password != '' && this.username != null && this.password != null) {
        console.log(this.username);
        console.log(this.password);
        alert("not ok");
      }
    */
