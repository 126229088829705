import { Component, OnInit } from '@angular/core';
import { Account, Environment } from '../../shared/pos-store.model';
import { DataService } from '../../shared/data.service';
import { DatastorageService } from '../../shared/datastorage.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  constructor(private _data: DataService, private _ds: DatastorageService) { 
  }


  tabs = [];
  subscription;
  environments;
  checked: boolean;

  ngOnInit() {
    this.environments = this._data.originalEnvironments;
    for (let environment of this.environments)
    {
      let enabled = true;
      let item = this._ds.getEnvironmentsForLogin().find(env => env.EnvID == environment.id);
      if (item)
        enabled = item.enabled;
      this.tabs.push({'name': environment.name, 'id': environment.id, 'content': environment.name, 'enabled': enabled});
    }
  }

  changestate(environment, event) {
//    console.log(environment);
//    console.log(event.checked);
    let env = new Environment(environment.id, environment.name);
    env.enabled = event.checked;
    this._ds.setEnvironmentForLogin(env);
    console.log("hhh");
    console.log(this._ds.settings);
    console.log("hhh");
    this._data.setFilters(this._ds.settings);

  }
}
