import { Injectable } from '@angular/core';

@Injectable()
export class TranslateService {

  constructor() { }

  languages = [{'code': 'nl', 'desc': 'Nederlands', 'flag': 'flag_nl'},
               {'code': 'fr', 'desc': 'Francais', 'flag': 'flag_fr'},
               {'code': 'en', 'desc': 'English', 'flag': 'flag_en'}
              ];

  translations = [{'id': 'Settings', 'translations': [{'lang':'nl', 'translation': 'Instellingen'},
                                                      {'lang':'fr', 'translation': 'Paramètres'},
                                                      {'lang':'en', 'translation': 'Settings'}
                ]},
                  {'id': 'Today', 'translations': [{'lang':'nl', 'translation': 'Vandaag'},
                                                     {'lang':'fr', 'translation': 'Aujourd\'hui'},
                                                     {'lang':'en', 'translation': 'Today'}
                ]},
                {'id': 'Revenue', 'translations': [{'lang':'nl', 'translation': 'Omzet'},
                                                {'lang':'fr', 'translation': 'Revenu'},
                                                {'lang':'en', 'translation': 'Revenue'}
                ]},
              ];

  public translate(field, language) {
    console.log(language);
    var value = this.translations.filter(function (el) {
      return (el.id === field);
    });
    var translation = value[0]['translations'].filter(function (el) {
      return(el.lang === language);
    })
    return translation[0]['translation'];
  }

}
