import { Component, OnInit } from '@angular/core';
import { WebService } from '../../shared/web.service';
import { DataService } from '../../shared/data.service';
import { User } from '../../shared/user.model';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { Router, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-dashboard1',
  templateUrl: './dashboard1.component.html',
  styleUrls: ['./dashboard1.component.css']
})
export class Dashboard1Component implements OnInit {

  constructor(private _web: WebService, private _data: DataService, private router: Router) {
    console.log("constructor DB1");
    this.selectedPeriod = undefined;
    this.selectedEnvironment = undefined;
    this.subscription = this._data.currentEnv.subscribe((env: any) => {
      this.onEnvironmentSelected(env.id)
		});
  }


  ngOnDestroy() {
    console.log("ngOnDestroy DB1");
//    this._data.periods = undefined;
//    this.cards = undefined;
//    this.subscription.unsubscribe();
//    this.selectedEnvironment = undefined;
  }

  cards = [];
  subscription;
  selectedPeriod: string;
  selectedEnvironment;
  userLoggedin: User;

  ngOnInit() { 
    this.userLoggedin = JSON.parse(localStorage.getItem("user"));
    this._web.token = this.userLoggedin.token;
    if (this.selectedEnvironment != undefined && this._data.periods != undefined) {
      this.refreshData(this.selectedEnvironment, this._data.periods);
      console.log("refresh PeriodSelected REFRESH");
    } else {
      console.log("refresh PeriodSelected NO REFRESH");
      console.log(this.selectedEnvironment);
      console.log(this._data.periods);
    }
  }

  onPeriodSelected(e) {
    console.log("PeriodSelected DB1");
    console.log(e);
    this._data.periods = e; //JSON.parse(e);
    console.log(this.selectedEnvironment);
    console.log(this._data.periods);
    if (this.selectedEnvironment != undefined && this._data.periods != undefined) {
      this.refreshData(this.selectedEnvironment, this._data.periods);
      console.log("refresh PeriodSelected REFRESH");
    } else {
      console.log("refresh PeriodSelected NO REFRESH");
      console.log(this.selectedEnvironment);
      console.log(this._data.periods);
    }
  }

  onEnvironmentSelected(account: string) {
    this.cards = undefined;
    console.log("envSelected DB1");
    console.log(this._data.periods);
    this.selectedEnvironment = account;
    console.log(this.selectedEnvironment);
  }

  refreshData(account, period) {
    this.cards = [];
    forkJoin(
      this._web.getRevenue(account, period.period.from, period.period.till, [], [], []),
      this._web.getRevenue(account, period.compare.from, period.compare.till, [], [], [])
    )
    .subscribe(([res1, res2]) => {
      if (this.cards == undefined)
        this.cards = [];
      this.cards.push(this.createCard(1, 'cards.turnover', this.anyToFloat(res1[0].tRevenue), this.anyToFloat(res2[0].tRevenue), 0));
      this.cards.push(this.createCard(2, 'cards.pieces', this.anyToFloat(res1[0].tQuantity), this.anyToFloat(res2[0].tQuantity), 0));
      this.cards.push(this.createCard(3, 'cards.tickets', this.anyToFloat(res1[0].tTickets), this.anyToFloat(res2[0].tTickets), 0));
      this.cards.push(this.createCard(4, 'cards.avgprpti', 
              this.anyToFloat(res1[0].tTickets) == 0 ? 0 : this.anyToFloat(res1[0].tRevenue)/this.anyToFloat(res1[0].tTickets), 
              this.anyToFloat(res2[0].tTickets) == 0 ? 0 : this.anyToFloat(res1[0].tRevenue)/this.anyToFloat(res2[0].tTickets), 0));
      this.cards.push(this.createCard(5, 'cards.avgprppi', 
              this.anyToFloat(res1[0].tQuantity) == 0 ? 0 : this.anyToFloat(res1[0].tRevenue)/this.anyToFloat(res1[0].tQuantity), 
              this.anyToFloat(res2[0].tQuantity) == 0 ? 0 : this.anyToFloat(res2[0].tRevenue)/this.anyToFloat(res2[0].tQuantity), 0));
      this.cards.push(this.createCard(6, 'cards.avgpipti', 
              this.anyToFloat(res1[0].tTickets) == 0 ? 0 : this.anyToFloat(res1[0].tQuantity)/this.anyToFloat(res1[0].tTickets), 
              this.anyToFloat(res2[0].tTickets) == 0 ? 0 : this.anyToFloat(res2[0].tQuantity)/this.anyToFloat(res2[0].tTickets), 0));
      });

  }

  anyToFloat(num: any): number {
    if(!num) {
      return 0;
    }
    else {
      return parseFloat(num.toString());
    }
  }

  createCard(id: number, description: string, value: number, compare: number, decimals) {
    return {'id': id,
    'description': description,
    'value': value,
    'compare': compare,
    'class': value >= compare ? 'pos' : 'neg',
    'arrow': value >= compare ? 'arrow_upward' : 'arrow_downward',
    'pct': Math.floor(compare) == 0 ? 0 : Math.round(-(100 - (Math.floor(value) / Math.floor(compare) * 100))),
    'bar': Math.floor(compare) == 0 ? 0 : 
           Math.round(-(100 - (Math.floor(value) / Math.floor(compare) * 100))) < 0 ? 
           Math.round(100-(100 - (Math.floor(value) / Math.floor(compare) * 100))) : 
           Math.round(-(100 - (Math.floor(value) / Math.floor(compare) * 100))),
    'decimal': decimals
   }
  }

  cardclick(e) {
    console.log(e);
    this.subscription.unsubscribe();
    let params : NavigationExtras = {
      queryParams: {"card" : e.description //, 'value': 'tRevenue'
      }
    }
    this.router.navigate(["/home/detail"], params);
  }

}
