import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatMenuModule, 
    MatProgressBarModule, 
    MatDividerModule, 
    MatSidenavModule,
    MatExpansionModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatTableModule,
    MatSortModule,
    MatSnackBarModule
    } from '@angular/material';

@NgModule({
  imports: [
    MatToolbarModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatMenuModule, 
    MatProgressBarModule, 
    MatDividerModule, 
    MatSidenavModule,
    MatExpansionModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatTableModule,
    MatSortModule,
    MatSnackBarModule
  ],
  exports: [  
    MatToolbarModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatMenuModule, 
    MatProgressBarModule, 
    MatDividerModule, 
    MatSidenavModule,
    MatExpansionModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatTableModule,
    MatSortModule,
    MatSnackBarModule
  ],
})
export class MaterialModule { }