import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { WebService } from '../../shared/web.service';
import { DataService } from '../../shared/data.service';
import { User } from '../../shared/user.model';
import { MatSort, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DetailComponent implements OnInit {

  @ViewChild('tabGroup') tabGroup;
  @ViewChild(MatSort) sort: MatSort;

  tabs = [
    {'name': 'detail.hour', 'groupby': 'hour'},
    {'name': 'detail.pos', 'groupby': 'pos'},
    {'name': 'detail.supplier', 'groupby': 'supplier'},
    {'name': 'detail.season', 'groupby': 'season'},
    {'name': 'detail.cat_1', 'groupby': 'category_1'},
    {'name': 'detail.cat_2', 'groupby': 'category_2'},
//    {'name': 'detail.cat_3', 'groupby': 'category_3'},
//    {'name': 'detail.cat_4', 'groupby': 'category_4'},
//    {'name': 'detail.cat_5', 'groupby': 'category_5'},
//    {'name': 'day', 'groupby': 'day'}
  ];

  detailDescription: string;
  subscription;
  selectedEnvironment;
  selectedPeriod: string;
  userLoggedin: User;
  list: Array<any> = []
  tablelist: MatTableDataSource<any>;
  
  countItems: number;

  displayedColumns: string[];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;


  constructor(private _web: WebService, private route: ActivatedRoute, private _data: DataService) { 
    this.route.queryParams.subscribe(params => {
//      console.log(params.card);
      this.detailDescription = params.card;
    });

    this.subscription = this._data.currentEnv.subscribe((env: any) => {
      this.onEnvironmentSelected(env.id)
		});
  }

  ngOnInit() {
    this.tabs.push({'name': 'detail.cat_3', 'groupby': 'category_3'});

    this.userLoggedin = JSON.parse(localStorage.getItem("user"));
    this._web.token = this.userLoggedin.token;

    if (this.selectedEnvironment != undefined) {
      this._web.getStores(this.selectedEnvironment).subscribe((res:any) => {
  //      console.log("hallo" + res.length);
        if (res.length > 1) {
          this.tabs.unshift({'name': 'detail.store', 'groupby': 'store'});
        }
  //      this._data.switchEnv(res.accounts[0]);
        if (this.selectedEnvironment != undefined && this._data.periods != undefined) {
          this.refreshData(this.selectedEnvironment, this._data.periods);
          console.log("refresh PeriodSelected REFRESH");
        } else {
          console.log("refresh PeriodSelected NO REFRESH");
          console.log(this.selectedEnvironment);
          console.log(this._data.periods);
        }
      });
    }



  }

  refreshData(account, period) {
    this.list = [];
    let groupby = [this.tabs[0].groupby];
    if (this.tabGroup.selectedIndex) 
      groupby = [this.tabs[this.tabGroup.selectedIndex].groupby]; //store, pos, supplier, season
    
    let groupid;
    let groupdesc;
    if (groupby[0] == 'hour')
    {
      groupdesc = groupby[0];
      groupid = groupby[0];
    }
    else
    {
      groupid = groupby[0]+'_id';
      groupdesc = groupby[0]+'_desc';
    } 

    this.list = [];
    forkJoin(
      this._web.getRevenue(account, period.period.from, period.period.till, [], [], groupby),
      this._web.getRevenue(account, period.compare.from, period.compare.till, [], [], groupby)
    )
    .subscribe(([res1, res2]) => {
      const resArray = Object.keys(res1).map(i => res1[i]);
      const resArray2 = Object.keys(res2).map(i => res2[i]);

      let result = resArray.map((a)=>{
        let obj2 = resArray2.find((b)=> a[groupid] === b[groupid]);
        let compare;
        if(obj2) {
          compare = {'compare': obj2};
        }
        else
        {
          compare = {'compare': []};
        }
        Object.assign(a,compare);
        return a;
       });

       resArray2.forEach((item, index) => {
        let compare = resArray.find(e => e[groupid] === item[groupid])
        if(compare == undefined) {
          let newitem = {};
          newitem[groupid]= item[groupid];
          newitem[groupdesc]= item[groupdesc];
          newitem['compare'] = item;
          result.push(newitem);
        }
       });

       for(var i = 0; i< result.length; i++) {
        let grpdesc;
        if (groupby[0] == 'hour') {
          let from = result[i][groupdesc];
          if (from < 10)
            from = '0'+ from; 
          let till = +result[i][groupdesc] + 1;
          grpdesc = from + ':00 - ' + till + ':00';
        }
        else {
          grpdesc = result[i][groupdesc];
        }
        this.list.push(this.composeItem(result[i], groupid, grpdesc, this.detailDescription));
       }

       console.log(this.list);

      if (groupdesc == 'hour')
      {
        var index = this.displayedColumns.indexOf('id');
        if (index !== -1) this.displayedColumns.splice(index, 1);
      } else
      {
        this.list.sort((a,b)=> {
          if (b.value > a.value) {
            return 1;
          }
          if (b.value < a.value) {
            return -1;
          }
          return 0;
        });
      }
      this.tablelist = new MatTableDataSource<any>(this.list);
      this.tablelist.sort = this.sort;
      this.countItems = this.list.length;
    });

  }


  composeItem(line: any, groupid: string, groupby: string, detail: string): any {
    let value;
    let pct;
    let compare;
    let delta;

    switch(detail) {
      case 'cards.turnover': {
        value = parseFloat(((line['tRevenue'] == undefined) ? 0 : line['tRevenue'])).toFixed(2);
        pct = 0;//Math.round(parseFloat(line['tRevenue']) / this.getTotal(res1, 'tRevenue', 2) * 100 * 100) / 100;
        this.displayedColumns = ['id', 'desc', 'value', 'compare', 'delta'];
        compare = parseFloat(((line['compare']['tRevenue'] == undefined) ? 0 : line['compare']['tRevenue'])).toFixed(2);
        delta = Math.floor(compare) == 0 ? 0 : Math.round(-(100 - (Math.floor(value) / Math.floor(compare) * 100)));
        break;
      }
      case 'cards.pieces': {
        value = parseFloat(((line['tQuantity'] == undefined) ? 0 : line['tQuantity'])).toFixed(0);
        pct = 0; //Math.round(parseFloat(line['tQuantity']) / this.getTotal(res1, 'tQuantity', 2) * 100 * 100) / 100;
        this.displayedColumns = ['id', 'desc', 'value', 'pct', 'compare'];
        compare = 0;
        delta = 0;
        break;
      }
      case 'cards.tickets': {
        value = parseFloat(line['tTickets']);
        pct = 0; //Math.round(parseFloat(line['tTickets']) / this.getTotal(res1, 'tTickets', 2) * 100 * 100) / 100;
        this.displayedColumns = ['id', 'desc', 'value', 'pct'];
        compare = 0;
        delta = 0;
        break;
      }
      case 'cards.avgprpti': {
        value = Math.round(parseFloat(line['tTickets']) == 0 || line['tTickets'] == undefined ? 0 : parseFloat(line['tRevenue'])/parseFloat(line['tTickets']) * 100 ) / 100;
        pct = 0;
        this.displayedColumns = ['id', 'desc', 'value'];
        compare = 0;
        delta = 0;
        break;
      }
      case 'cards.avgprppi': {
        value = Math.round(parseFloat(line['tQuantity']) == 0 || line['tQuantity'] == undefined ? 0 : parseFloat(line['tRevenue'])/parseFloat(line['tQuantity']) * 100 ) / 100;
        pct = 0;
        this.displayedColumns = ['id', 'desc', 'value'];
        compare = 0;
        delta = 0;
        break;
      }
      case 'cards.avgpipti': {
        value = Math.round(parseFloat(line['tTickets']) == 0 || line['tTickets'] == undefined ? 0 : parseFloat(line['tQuantity'])/parseFloat(line['tTickets']) * 100 ) / 100;
        pct = 0;
        this.displayedColumns = ['id', 'desc', 'value'];
        compare = 0;
        delta = 0;
        break;
      }
      default: {
        value = 0;
        pct = 0;
        this.displayedColumns = ['id', 'desc', 'value'];
        compare = 0;
        delta = 0;
        break
      }
    }
  
    let item = {'id':line[groupid], 
                'desc': groupby, 
                'value': value,
                'pct': pct,
                'compare': compare,
                'delta': delta
               };
    return item;

  }

  getTotal(arr: any, obj, comma): number {
    var total = 0;
    if (arr != null && arr.length > 0) {      
      arr.forEach(x => total += Number(x[obj]));
    }
    return Math.round(total * Math.pow(10, comma)) / Math.pow(10, comma);
  }  

  onPeriodSelected(e) {
    console.log('onperiodselect - detail');
    console.log(e);
    this._data.periods = e; //JSON.parse(e);
    console.log(this.selectedEnvironment);
    console.log(this._data.periods);
    if (this.selectedEnvironment != undefined && this._data.periods != undefined) {
      this.refreshData(this.selectedEnvironment, this._data.periods);
      console.log("refresh PeriodSelected REFRESH");
    } else {
      console.log("refresh PeriodSelected NO REFRESH");
      console.log(this.selectedEnvironment);
      console.log(this._data.periods);
    }
  }


  onEnvironmentSelected(account: string) {
    console.log("envSelected detail");
    this.selectedEnvironment = account;
    }

  tabclick(groupby_id) {
    this.refreshData(this.selectedEnvironment, this._data.periods);
  }


}

/*
      for (var i = 0; i < resArray.length; i++){
        let value;
        let pct;
        let compare = 0;
        let grpdesc;
        if (groupby[0] == 'hour') {
          let from = resArray[i][groupdesc];
          let till = +resArray[i][groupdesc] + 1;
          grpdesc = from + ':00 - ' + till + ':00';
        }
        else
          grpdesc = resArray[i][groupdesc];
        switch(this.detailDescription) {
          case 'cards.turnover': {
            value = parseFloat(resArray[i]['tRevenue']);
            pct = Math.round(parseFloat(resArray[i]['tRevenue']) / this.getTotal(res1, 'tRevenue', 2) * 100 * 100) / 100;
            this.displayedColumns = ['id', 'desc', 'value', 'pct', 'compare'];
            compare = 0;
            break;
          }
          case 'cards.pieces': {
            value = parseFloat(resArray[i]['tQuantity']);
            pct = Math.round(parseFloat(resArray[i]['tQuantity']) / this.getTotal(res1, 'tQuantity', 2) * 100 * 100) / 100;
            this.displayedColumns = ['id', 'desc', 'value', 'pct', 'compare'];
            compare = 0;
            break;
          }
          case 'cards.tickets': {
            value = parseFloat(resArray[i]['tTickets']);
            pct = Math.round(parseFloat(resArray[i]['tTickets']) / this.getTotal(res1, 'tTickets', 2) * 100 * 100) / 100;
            this.displayedColumns = ['id', 'desc', 'value', 'pct'];
            compare = 0;
            break;
          }
          case 'cards.avgprpti': {
            value = Math.round(parseFloat(resArray[i]['tTickets']) == 0 ? 0 : parseFloat(resArray[i]['tRevenue'])/parseFloat(resArray[i]['tTickets']) * 100 ) / 100;
            pct = 0;
            this.displayedColumns = ['id', 'desc', 'value'];
            compare = 0;
            break;
          }
          case 'cards.avgprppi': {
            value = Math.round(parseFloat(resArray[i]['tQuantity']) == 0 ? 0 : parseFloat(resArray[i]['tRevenue'])/parseFloat(resArray[i]['tQuantity']) * 100 ) / 100;
            pct = 0;
            this.displayedColumns = ['id', 'desc', 'value'];
            compare = 0;
            break;
          }
          case 'cards.avgpipti': {
            value = Math.round(parseFloat(resArray[i]['tTickets']) == 0 ? 0 : parseFloat(resArray[i]['tQuantity'])/parseFloat(resArray[i]['tTickets']) * 100 ) / 100;
            pct = 0;
            this.displayedColumns = ['id', 'desc', 'value'];
            compare = 0;
            break;
          }
          default: {
            value = 0;
            pct = 0;
            this.displayedColumns = ['id', 'desc', 'value'];
            compare = 0;
            break
          }
        }
      
        let item = {'id':resArray[i][groupid], 
                    'desc': grpdesc, 
                    'value': value,
                    'pct': pct,
                    'compare': compare
                   };
//        this.list.push(item);
      }

      */