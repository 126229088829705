import { DomSanitizer } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material/icon";
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomIconService {

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}
  init() {
    this.matIconRegistry.addSvgIcon(
      "flag_nl",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/flag_nl.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "flag_fr",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/flag_fr.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "flag_en",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/flag_en.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "flag_eu",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/flag_eu.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "flag_it",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/flag_it.svg")
    );
  }
}


