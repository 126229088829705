import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';

import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { UserComponent } from './user/user.component';
import { SignInComponent } from './user/sign-in/sign-in.component';
import { HomeComponent } from './home/home.component';

import { FormsModule} from '@angular/forms';
import { WebService } from './shared/web.service';
import { RoutesRecognized } from '@angular/router';
import { appRoutes } from './routes';
import { AuthGuard } from './auth/auth.guard';
import { CustomIconService } from "./shared/custom-icon.service";
import { TranslateService } from './shared/translate.service';
import { DateService } from './shared/date.service';
import { DatastorageService } from './shared/datastorage.service';

import { FlexLayoutModule } from '@angular/flex-layout';
import { DatefilterComponent } from './home/datefilter/datefilter.component';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { TitlebarComponent } from './home/titlebar/titlebar.component';
import { SettingsComponent } from './home/settings/settings.component';
import { Dashboard1Component } from './home/dashboard1/dashboard1.component';
import { DetailComponent } from './home/detail/detail.component';

@NgModule({
  declarations: [
    AppComponent,
    UserComponent,
    SignInComponent,
    HomeComponent,
    DatefilterComponent,
    TitlebarComponent,
    SettingsComponent,
    Dashboard1Component,
    DetailComponent
  ],
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RouterModule.forRoot(appRoutes),
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    FormsModule,
    FlexLayoutModule
  ],
  providers: [WebService, AuthGuard, CustomIconService, TranslateService, DateService, DatastorageService],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}