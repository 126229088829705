import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs';
import { enableDebugTools } from '@angular/platform-browser';

export interface envir {
	id: number | string;
	name: string;
  }

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor() { 
		this.currentAccount = JSON.parse(localStorage.getItem("user"));
//		this.filterEnvironments = JSON.parse(localStorage.getItem("settings"));
//		if (this.filterEnvironments) {
//			this.filterEnvironments =  this.filterEnvironments.find(account => account.AccountID == this.currentAccount['UserName'])['Environments'];
//			this.filterEnvironments = this.filterEnvironments.filter(function(env) { return env.enabled == false});
//		} else {
			this.filterEnvironments = [];
//		}
		console.log(this.filterEnvironments);
	}

  public environments = new BehaviorSubject([]);
	public currentEnv = new BehaviorSubject({}); //{name: 'Account 1', id: '1'}
//	public getListener = new BehaviorSubject({});
	public periods: Array<any>;
	public originalEnvironments: Array<any>;
	public filterEnvironments: Array<any>;
	private currentAccount;

  public switchEnv(newEnv: any): void {
		console.log("switch");
		if(newEnv != undefined) {
			console.log(newEnv);
			this.currentEnv.next({name: newEnv.name, id: newEnv.id});
		}
		else
			this.currentEnv.next({});
		}
		
  public setEnvironments(newEnv): void {
		console.log("setenv");
		if(newEnv != undefined) {
			this.originalEnvironments = newEnv;
			this.environments.next(this.removeFilteredEnvironments());
		}
		else
			this.environments.next([]);
	}

	public setFilters(filters: any): void {
		if(filters != undefined) {
				filters =  filters.find(account => account.AccountID == this.currentAccount['UserName'])['Environments'];
				this.filterEnvironments = filters.filter(function(env) { return env.enabled == false});
			} else {
				this.filterEnvironments = [];
			}
		this.environments.next(this.removeFilteredEnvironments());
	}


	private removeFilteredEnvironments() {
		var newlist = JSON.parse(JSON.stringify( this.originalEnvironments ));	
		console.log(newlist);
		console.log(this.filterEnvironments);
//		newlist.findindex
		if(this.filterEnvironments) {
			for (let j = 0; j < this.filterEnvironments.length; j++) {
				let i = newlist.findIndex(env => env.id == this.filterEnvironments[j].EnvID);
				console.log(i);
				if (i != -1) {
					newlist.splice(i,1);
					console.log(newlist);
				}
			}
		}
		return newlist;
	}
}


