import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';
import { forkJoin } from "rxjs/observable/forkJoin";

//import 'rxjs/add/operator/toPromise';

@Injectable({
	providedIn: 'root'
})
export class WebService {

	constructor(private _http: HttpClient) {
		this.url = "https://api.softtouch.eu:443/1/accounts/";
	}
	public token: string;
	private url: string;

	getStores(account: string) {
		const headers = new HttpHeaders()
			.set("Content-Type", "application/json")
			.set('Authorization', this.token);
		return this._http.get(this.url + account + "/stores?take=100", { headers })
			.map(result => result);
	}

	getStoresFromAccounts(accounts: Array<string>) {
		const headers = new HttpHeaders()
			.set("Content-Type", "application/json")
			.set('Authorization', this.token);
		let calls = [];
		for (let acc of accounts)
		{
			calls.push(this._http.get(this.url + acc + "/stores?take=100", { headers }));
		}
		return forkJoin(calls);	
	}

	getPos(account: string) {
		const headers = new HttpHeaders()
			.set("Content-Type", "application/json")
			.set('Authorization', this.token);
		return this._http.get(this.url + account + "/pos?take=100", { headers })
			.map(result => result);
	}

	getPosFromAccounts(accounts: Array<string>) {
		const headers = new HttpHeaders()
			.set("Content-Type", "application/json")
			.set('Authorization', this.token);
		let calls = [];
		for (let acc of accounts)
		{
			calls.push(this._http.get(this.url + acc + "/pos?take=100", { headers }));
		}
		return forkJoin(calls);	
	}

	getRevenue(account: Array<any>, fromdate: string, todate: string, stores: Array<string>, pos: Array<string>, groupby: Array<string>) {
		const headers = new HttpHeaders()
		.set("Content-Type", "application/json")
		.set('Authorization', this.token);
		var body = { "from_date": fromdate, 
					 "to_date": todate,
					 "stores" : stores,
					 "group_by" : groupby
				};
	
		return this._http.post(this.url + account + "/dashboard/revenue-per-entity", body, { headers })
			.map(result => result);
	}
}