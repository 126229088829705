import { Component } from '@angular/core';
import { CustomIconService } from './shared/custom-icon.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'app';
  constructor(private customIconService: CustomIconService, private _translate: TranslateService) {
    this.customIconService.init();
    // this language will be used as a fallback when a translation isn't found in the current language
    _translate.setDefaultLang('nl');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    _translate.use('nl');
  }
}