import { Injectable } from '@angular/core';
import { Account, Environment } from './pos-store.model';

@Injectable({
  providedIn: 'root'
})
export class DatastorageService {

  settings : Array<Account> = [];
  currentAccount: string;

  constructor() { 
    this.settings = JSON.parse(localStorage.getItem("settings"));
    this.currentAccount = JSON.parse(localStorage.getItem("user"));
  }

  getEnvironmentsForLogin() {
    if (this.settings)
      return this.settings.find(account => account.AccountID == this.currentAccount['UserName']).Environments;
    else 
      return [];
  }

  setEnvironmentForLogin(environment: Environment) {
    console.log(this.currentAccount['UserName']);
    console.log(environment);

    if (!this.settings) {
      this.settings = [];
    }
    let i = this.settings.findIndex(account => account.AccountID == this.currentAccount['UserName']);
    console.log(i);
    if (i == -1) {
      let newAccount = new Account(this.currentAccount['UserName']);
      newAccount.Environments = [];
      newAccount.Environments.push(environment);
      this.settings.push(newAccount);
    } else {
      let j = this.settings[i].Environments.findIndex(env => env.EnvID == environment.EnvID);
      if (j == -1) {
        this.settings[i].Environments.push(environment);
      } else
      {
        this.settings[i].Environments[j] = environment;
        console.log(this.settings[i].Environments);
      }
    }
    console.log(this.settings);
    this.saveSettings();
  }

  saveSettings() {
    localStorage.setItem("settings", JSON.stringify(this.settings));
    console.log(this.settings);
  }
}
